import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import Cta from '../components/cta'
import IndustriesPartners from '../components/industriesPartners'
import IndustriesPlatform from '../components/industriesPlatform'
import IndustriesPlatforms from '../components/industriesPlatforms'
import ColumnAlternatingButton from '../components/columnAlternatingButton'

export default ({ data, location }) => {
  const post = data.markdownRemark
  const partners = data.partners
  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <MDBContainer>
              <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <Fragment key={index}>
                      <ColumnAlternatingButton
                        textCol={'7'}
                        imageCol={'5'}
                        title={sections.title}
                        subtitle={sections.subtitle}
                        description={sections.description}
                        imageUrl={sections.image.childImageSharp.fluid}
                        imageAltText={sections.alttext}
                        placement={sections.placement}
                        link={ sections.document ? sections.document.publicURL : sections.link }
                        linktext={sections.linktext}
                      />
                    </Fragment>
                  )
                })}
              </MDBAnimation>
            </MDBContainer>
          </section>
        ) : (
          ''
        )}
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {post.frontmatter.solution.title}
                  </h2>
                  <h3>{post.frontmatter.solution.subtitle}</h3>
                  <p className="text-medium">{post.frontmatter.solution.description}</p>
                </MDBCol>
              </MDBRow>

              <IndustriesPlatform
                platformdescription={post.frontmatter.platform.description}
                platformimage={ post.frontmatter.platform.image.childImageSharp.fluid }
                platformimagealttext={post.frontmatter.platform.alttext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>

        <section className="bg-gray-light">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-5">
                Industry platforms
              </h3>
              <MDBRow>
                {post.frontmatter.industry.map((industries, index) => {
                  return (
                    <Fragment key={index}>
                      {industries.frontmatter.platformsbyindustry.sort((a, b) => a.frontmatter.sort == b.frontmatter.sort ? 0 : 
                            a.frontmatter.sort < b.frontmatter.sort ? -1 : 1).map((platind, index) => {
                          return (
                            <Fragment key={index}>
                              {platind.frontmatter.section.map(
                                (sections, index) => {
                                  return (
                                    <IndustriesPlatforms
                                      key={index}
                                      image={sections.image}
                                      alttext={sections.alttext}
                                      title={sections.title}
                                      features={sections.feature}
                                      document={sections.document}
                                    />
                                  )
                                }
                              )}
                            </Fragment>
                          )
                        }
                      )}
                    </Fragment>
                  )
                })}
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              {post.frontmatter.partners.map((partner, index) => {
                return (
                  <IndustriesPartners
                    key={index}
                    partnerdescription={partner.frontmatter.partnerdescription}
                    partnerimage={partner.frontmatter.logos}
                    partnerlink={partner.frontmatter.link}
                    partnerlinktext={partner.frontmatter.linktext}
                  />
                )
              })}
            </MDBContainer>
          </MDBAnimation>
        </section>

        <section className="bg-blue-green-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.cta.title}
                ctadescription={post.frontmatter.cta.description}
                ctalink={post.frontmatter.cta.link}
                ctalinktext={post.frontmatter.cta.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </Layout>
    </div>
  )
}
export const query = graphql`
  query MyQuery2($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }       
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        meta {
          title
          description
          keywords
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug        
        section {
          title
          subtitle
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 540, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          document {
            publicURL
          }
        }
        solution {
          title
          subtitle
          description
        }
        platform {
          description
          image {
            childImageSharp {
              fluid(maxWidth: 431, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alttext
        }
        servercontent {
          server {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alttext
            description
            linktext
            link
          }
        }
        cta {
          title
          description
          linktext
          link
        }
        partners {
          frontmatter {
            partnername
            partnerdescription
            link
            linktext
            logos {
              image {
                childImageSharp {
                  fluid(maxWidth: 740, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alttext
            }
          }
        }
        industry {
          frontmatter {
            industryname
            platformsbyindustry {
              frontmatter {
                title
                subtitle
                sort
                section {
                  title
                  link
                  linktext
                  document {
                    publicURL
                  }
                  image {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  alttext
                  feature {
                    title
                    description
                    datasheetlink
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
